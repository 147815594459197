<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width:80px;padding: 0 10px">
                                <span>操作类型：</span>
                            </td>
                            <td>
                                <el-select v-model="logType" style='width: 100%' size="small" placeholder="请选择操作类型">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px;padding: 0 10px">
                                <span>操作模块：</span>
                            </td>
                            <td>
                                <el-input type="text" v-model="module" size="small"
                                          placeholder="请输入操作模块"/>
                            </td>
                            <td style="width:80px;padding: 0 10px">
                                <span>操作人员：</span>
                            </td>
                            <td>
                                <el-input type="text" v-model="userName" size="small" placeholder="请输入操作人员"></el-input>
                            </td>
                            <td style="width: 80px;padding: 0 10px">
                                <span>时间区间：</span>
                            </td>
                            <td style="padding: 0 10px">
                                <el-date-picker
                                        v-model="time"
                                        type="daterange"
                                        align="right"
                                        unlink-panels
                                        size="small"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        :default-time="['00:00:00', '23:59:59']"
                                        :picker-options="pickerOptions">
                                </el-date-picker>&emsp;
                            </td>
                            <td style="width: 200px">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                style="width: 100%;height: 100%;" :border="true"
                                :max-height="tableHeight"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column prop="operateModule" label="模块" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="operateDetail" label="操作详情" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userName" label="操作人员" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="operateUrl" label="调用接口" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userIp" label="操作ip" width="180" align="center"></el-table-column>
                            <el-table-column prop="userLocation" label="操作地址" width="180"
                                             align="center"></el-table-column>
                            <el-table-column prop="createTime" label="操作时间" width="180"
                                             align="center"></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import log from '../../../public/json/log.json'
    import select from '../../../public/json/select.json'

    export default {
        name: "Log",
        data() {
            return {
                pageList: [16,20,30,50],
                tableHeight: '',
                options: [
                    {
                        label: '操作日志',
                        value: 0,
                    },
                    {
                        label: '登录日志',
                        value: 1,
                    }
                ],
                tableData: [],
                userName: '',
                lineHeight: '',
                // totalPage:0,
                totalPage: 30,
                option: {
                    pageIndex: '',
                    pageSize: 16,
                    currentPage: 2
                },
                pageIndex: 1,
                logType: 0,
                id: '',
                ipAddr: '',
                module: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                time: ''
            }
        },
        methods: {
            searchBtu() {
                this.pageChange(1)
            },
            reset() {
                this.module = '';
                this.userName = '';
                this.ipAddr = '';
                this.time = '';
                this.pageChange(1);
            },
            pageChange(option) {
                this.option.pageIndex = option
                this.$api.getLogs({
                    operateModule: this.module,
                    userName: this.userName,
                    logType: this.logType,
                    pageSize: 16,
                    startDate: this.time[0],
                    endDate: this.time[1],
                    pageIndex: option,
                }).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val){
                this.$api.getLogs({
                    operateModule: this.module,
                    userName: this.userName,
                    logType: this.logType,
                    pageSize: val,
                    startDate: this.time[0],
                    endDate: this.time[1],
                    pageIndex: 1,
                }).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            }
        },
        created() {
            this.tableData = this.searchBtu();
            this.tableHeight = (window.innerHeight)*0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        },
    }
</script>

<style lang="scss" scoped>
    .dialog-body {
        display: flex;
        width: 100%;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .handle-box {
        padding: 5px;

    }

    .page-body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 5%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
        overflow-y: auto;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>
